<template>
    <div class="max-w-sm mx-auto border flex flex-col gap-2">
        <!-- titre -->
        <div class="text-4xl border text-blue-600">
            Loup Solitaire
            <button v-if="lock==1" class="float-right p-4 border border-gray-600 rounded-lg bg-gray-100 hover:bg-gray-200 w-12 mt-1 mr-1" @click="lock=0"><LockClosed /></button>
            <button v-else         class="float-right p-4 border border-gray-600 rounded-lg bg-gray-100 hover:bg-gray-200 w-12 mt-1 mr-1" @click="lock=1"><LockOpen /></button>
        </div>
        <!-- habileté et endurance -->
        <div class="flex flex-row justify-between gap-1">
            <Compteur title="Habileté"  class="w-full" :val="lsH.count"  @changeVal="changeHabilete"  couleur="text-blue-600" />
            <Compteur title="Endurance" class="w-full" :val="lsE.count"  @changeVal="changeEndurance" couleur="text-blue-600" />
        </div>
        <!-- ennemi -->
        <div class="border">
            <div class="flex flex-col justify-between">
                <div class="text-3xl bg-red-100 text-red-600 border border-red-600 py-4 my-1" @click="toggleEnnemi">
                    Ennemi

                </div>
                <div v-show="displayEnnemi == 1">
                    <div class="flex flex-row justify-between gap-1">
                        <Compteur title="Habileté"  class="w-full" :val="eH.count"  @changeVal="changeHabileteE"  couleur="text-red-600" />
                        <Compteur title="Endurance" class="w-full" :val="eE.count"  @changeVal="changeEnduranceE" couleur="text-red-600" />
                    </div>
                    <div class="text-3xl text-red-600">
                        Quotient: 
                        <div class="inline-block font-black">{{ lsH.count.value - eH.count.value }}</div>
                    </div>
                    <button 
                        class="font-black text-red-600 text-4xl border-2 border-red-600 bg-red-200 rounded-lg p-4 my-1"
                        @click="combat">
                        COMBAT !
                    </button>
                </div>
            </div>
        </div>
        <div>
            <button class="w-full border border-gray-400 bg-gray-100 " @click="showTableDeHasard()">
                <div class="font-bold text-gray-700 text-2xl">Table de Hasard</div>
                Dernier résultat : <div class="inline-block text-3xl text-blue-700">{{ resultTH }}</div>
            </button>
        </div>
        <!-- bourse -->
        <div class="border border-blue-600">
            <div class="flex flex-row justify-between">
                <button class="border border-gray-600 bg-gray-100 px-4" @click="bourse.dec(1)">-1</button>
                <div class="inline-block text-2xl text-blue-700 font-bold">
                    Bourse : 
                </div>
                <div class="inline-block text-4xl text-blue-700">
                    {{ bourse.count }} 
                </div>
                <div class="inline-block">
                    couronnes
                </div>
                <button class="border border-gray-600 bg-gray-100 px-4" @click="bourse.inc(1)">+1</button>
            </div>
        </div>
        <!-- compétences -->
        <div  class="p-4 text-left">
            <div class="text-2xl text-blue-700 font-bold">Compétences:</div>
            <div><input type="checkbox" :disabled="lock"> Camouflage</div>
            <div><input type="checkbox" :disabled="lock"> L'orientation</div>
            <div><input type="checkbox" :disabled="lock"> Communication animale</div>
            <div><input type="checkbox" :disabled="lock"> Maitrise psychique de la matière</div>
            <div><input type="checkbox" :disabled="lock"> Guérison</div>
            <div><input type="checkbox" :disabled="lock"> Chasseur</div>
            <div><input type="checkbox" :disabled="lock"> Bouclier psychique</div>
            <div><input type="checkbox" :disabled="lock"> 6e sens</div>
            <div><input type="checkbox" :disabled="lock"> Puissance psychique</div>
            <div>
                <input type="checkbox" :disabled="lock">
                Maître d'arme : 
                <select :disabled="lock">
                    <option>0. Poignard</option>
                    <option>1. Lance</option>
                    <option>2. Masse d'arme</option>
                    <option>3. Sabre</option>
                    <option>4. Marteau</option>
                    <option>5. Epée</option>
                    <option>6. Hache</option>
                    <option>7. Epée</option>
                    <option>8. Baton</option>
                    <option>9. Glaive</option>
                </select>
            </div>
        </div>
        <!-- armes -->
        <div class="flex flex-col justify-center">
            <div class="text-left ml-2 text-2xl text-blue-700 font-bold">Armes</div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">1.</div>
                <input class="border grow mr-2" type="text">
            </div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">2.</div>
                <input class="border grow mr-2" type="text">
            </div>
        </div>
       <!-- sac à dos -->
        <div class="flex flex-col p-2 justify-center border">
            <div class="text-left ml-2 text-2xl text-blue-700 font-bold">Sac à dos</div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">1.</div>
                <input class="border grow mr-2" type="text">
            </div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">2.</div>
                <input class="border grow mr-2" type="text">
            </div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">3.</div>
                <input class="border grow mr-2" type="text">
            </div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">4.</div>
                <input class="border grow mr-2" type="text">
            </div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">5.</div>
                <input class="border grow mr-2" type="text">
            </div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">6.</div>
                <input class="border grow mr-2" type="text">
            </div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">7.</div>
                <input class="border grow mr-2" type="text">
            </div>
            <div class="w-full flex flex-row gap-2">
                <div class="inline-block ml-2">8.</div>
                <input class="border grow mr-2" type="text">
            </div>
        </div>
         <!--  objets spéciaux -->
        <div class="border px-2">
            <div class="text-left ml-2 text-2xl text-blue-700 font-bold">Objets spéciaux</div>
            <textarea class="border w-full" />
        </div>
    </div>
    <div v-if="displayTH" class="w-screen h-screen border-2 fixed top-0 left-0 z-50">
        <TableDeHasard @hideTable="hideTableDeHasard" />
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TableDeHasard from "@/components/TableDeHasard.vue"
import Compteur from "@/components/Compteur.vue"
// import { useCounter } from '@vueuse/core'
import { useCounter } from "@/services/Counter.js"
import LockClosed from "@/components/Svg/LockClosed.vue"
import LockOpen from "@/components/Svg/LockOpen.vue"


const lock = ref(0)

const lsE = useCounter(0)
const lsH = useCounter(0)
const eE = useCounter(1)
const eH = useCounter(0)

const bourse = useCounter(0)
bourse.setMax(50)

onMounted(() => {
    // lsE.inc(6)
    // lsE.dec(2)
    // console.log("lsE:", lsE.count.value)
})

// [ennemi, LS]
const tableDeDegats = {
    "-11": [ [6,0]  ,[0,999], [0,999], [0,8], [0,8], [1,7], [2,6], [3,5], [4,4], [5,3] ],
    "-10": [ [7,0]  ,[0,999], [0,8],   [0,7], [1,7], [2,6], [3,6], [4,5], [5,4], [6,3] ],
    "-9" : [ [7,0]  ,[0,999], [0,8],   [0,7], [1,7], [2,6], [3,6], [4,5], [5,4], [6,3] ],
    "-8" : [ [8,0]  ,[0,8]  , [0,7],   [1,6], [2,6], [3,5], [4,5], [5,4], [6,3], [7,2] ],
    "-7" : [ [8,0]  ,[0,8]  , [0,7],   [1,6], [2,6], [3,5], [4,5], [5,4], [6,3], [7,2] ],
    "-6" : [ [9,0]  ,[0,6]  , [1,6],   [2,5], [3,5], [4,4], [5,4], [6,3], [7,2], [8,0] ],
    "-5" : [ [9,0]  ,[0,6]  , [1,6],   [2,5], [3,5], [4,4], [5,4], [6,3], [7,2], [8,0] ],
    "-4" : [ [10,0] ,[1,6]  , [2,5],   [3,5], [4,4], [5,4], [6,3], [7,2], [8,1], [9,0] ],
    "-3" : [ [10,0] ,[1,6]  , [2,5],   [3,5], [4,4], [5,4], [6,3], [7,2], [8,1], [9,0] ],
    "-2" : [ [11,0] ,[2,5]  , [3,5],   [4,4], [5,4], [6,3], [7,2], [8,2], [9,1], [10,0] ],
    "-1" : [ [11,0] ,[2,5]  , [3,5],   [4,4], [5,4], [6,3], [7,2], [8,2], [9,1], [10,0] ],
    "0"  : [ [12,0] ,[3,5]  , [4,4],   [5,4], [6,3], [7,2], [8,2], [9,1], [10,0],[11,0] ],
    "1"  : [ [14,0] ,[4,5]  , [5,4],   [6,3], [7,3], [8,2], [9,2], [10,1],[11,0],[12,0] ],
    "2"  : [ [14,0] ,[4,5]  , [5,4],   [6,3], [7,3], [8,2], [9,2], [10,1],[11,0],[12,0] ],
    "3"  : [ [16,0] ,[5,4]  , [6,3],   [7,3], [8,2], [8,2], [10,2],[11,1],[12,0],[14,0] ],
    "4"  : [ [16,0] ,[5,4]  , [6,3],   [7,3], [8,2], [8,2], [10,2],[11,1],[12,0],[14,0] ],
    "5"  : [ [18,0] ,[6,4]  , [7,3],   [8,3], [9,2], [10,2],[11,1],[12,0],[14,0],[16,0] ],
    "6"  : [ [18,0] ,[6,4]  , [7,3],   [8,3], [9,2], [10,2],[11,1],[12,0],[14,0],[16,0] ],
    "7"  : [ [999,0],[7,4]  , [8,3],   [9,2], [10,2],[11,2],[12,1],[14,0],[16,0],[18,0] ],
    "8"  : [ [999,0],[7,4]  , [8,3],   [9,2], [10,2],[11,2],[12,1],[14,0],[16,0],[18,0] ],
    "9"  : [ [999,0],[8,3]  , [9,3],   [10,2],[11,2],[12,2],[14,1],[16,0],[18,0],[999,0] ],
    "10" : [ [999,0],[8,3]  , [9,3],   [10,2],[11,2],[12,2],[14,1],[16,0],[18,0],[999,0] ],
    "11" : [ [999,0],[9,3]  , [10,2],  [11,2],[12,2],[14,1],[16,1],[18,0],[999,0],[999,0] ],
}
components: {
    TableDeHasard,
    Compteur
}

/* +-----------------------------------
   | table de hasard
   +----------------------------------- */
let displayTH = ref(0)
const resultTH = ref(0)

const showTableDeHasard = () => {
    displayTH.value = 1
}
const hideTableDeHasard = (val) => {
    resultTH.value = val
    displayTH.value = 0
    // resoud le combat
    if (combatEnCours.value == 1){
        combatEnCours.value = 0
        let quotient = parseInt(lsE.count.value) - parseInt(eE.count.value)
        // console.log(`Quotient: ${quotient}`)
        if (quotient < -11) quotient = -11
        if (quotient > 11) quotient = 11
        const res = tableDeDegats[quotient.toString()][val]
        // console.log(`res: ${res.join(' / ')}`)
        eE.dec(res[0])
        lsE.dec(res[1])
        if (lsE.count.value <= 0){
            alert("Tu es mort !")
        }
        if (eE.count.value <= 0){
            alert("L'ennemi est mort !")
        }
    }
}

/* +-------------------------------------
   | gestion display de l'ennemi
   +------------------------------------- */
const displayEnnemi = ref(0)
const toggleEnnemi = () => {
    if (displayEnnemi.value){
        displayEnnemi.value = 0
    }
    else{
        displayEnnemi.value = 1
    }
}

/* +-----------------------------------
   | COMBAT !
   +----------------------------------- */
const combatEnCours = ref(0)
const combat = () => {
    combatEnCours.value = 1
    // ouvrir la table de hasard, et utiliser la valeur de retour
    displayTH.value = 1
}

/* +-----------------------------------
   | gestion de MON andurance
   +----------------------------------- */
const enduranceMax = ref(29)
lsE.setMax(enduranceMax)
const changeEndurance = (val) => {
    lsE.inc(parseInt(val))
    if (lsE.count.value <= 0){
        alert("Tu es mort ! R.I.P. ")
    }
    // console.log("DEBUG", val, lsE.count.value)
}

/* +-------------------------------------
   | gestion de l'andurance de l'ennemi
   +------------------------------------- */
const changeEnduranceE = (val) => {
    eE.inc(val)
    if (eE.count.value <= 0){
        alert("L'ennemi est mort !")
        eE.value = 0
        displayEnnemi.value = 0
    }
}

/* +--------------------------------------
   | gestion de MON habileté
   +-------------------------------------- */
const changeHabilete = (val) => {
    lsH.inc(val )
}

/* +----------------------------------------
   | gestion de l'habileté de l'ennemi
   +---------------------------------------- */
const changeHabileteE = (val) => {
    eH.inc(val )
}

/* +----------------------------------------
   | compétences
   +---------------------------------------- */
// reserved for future use, if I want to save this in localstorage

/* +----------------------------------------
   | armes
   +---------------------------------------- */
// reserved for future use, if I want to save this in localstorage

/* +----------------------------------------
   | sac à doc
   +---------------------------------------- */
// reserved for future use, if I want to save this in localstorage

/* +----------------------------------------
   | bourse
   +---------------------------------------- */


</script>