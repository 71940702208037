<template>
    <div class="flex flex-col h-screen bg-blue-100">
        <div 
            v-for="(l, lidx) of tableDeHasard" :key="'l_'+lidx"
            class="flex flex-row flex-grow w-screen gap-0 flex-nowrap justify-evenly justify-items-stretch "
            >
            <div
                class="cellule border border-blue-300 font-bold text-4xl w-full" 
                v-for="(c, cidx) of l" :key="`l_${lidx}_c_${cidx}`"
                @click="choiceMade(c)"
            >{{ c }}</div>
        </div>
    </div>
</template>

<script setup>

const tableDeHasard = [
    [1, 5, 7, 3, 6, 9, 0, 1, 7, 9],
    [3, 9, 2, 8, 1, 7, 4, 9, 7, 8],
    [6, 1, 0, 7, 3, 0, 5, 4, 6, 7],
    [0, 2, 8, 9, 2, 9, 6, 0, 2, 4],
    [5, 9, 6, 4, 8, 2, 8, 5, 6, 3],
    [0, 3, 1, 3, 9, 7, 5, 0, 1, 5],
    [5, 8, 2, 5, 1, 3, 6, 4, 3, 9],
    [7, 0, 4, 8, 6, 4, 5, 1, 4, 2],
    [4, 6, 8, 3, 2, 0, 1, 7, 2, 5],
    [8, 3, 7, 0, 9, 6, 2, 4, 8, 1]
]

const emit= defineEmits(["hideTable"])

const choiceMade = (mychoice) => {
    // console.log(`My choice is ${mychoice}`)
    emit("hideTable", mychoice);
}
</script>
