<template>
    <div class="border border-blue-600 text-center px-4">
        <div class="text-2xl font-bold" :class="couleur">{{ title }}</div>
        <div class="flex flex-row gap-0">
            <div class="flex flex-col grow-0">
                <button class="border border-gray-600 rounded-md bg-gray-100 hover:bg-gray-200 mx-2 mb-1 px-3" @click="changeVal(-1)">-1</button>
                <button class="border border-gray-600 rounded-md bg-gray-100 hover:bg-gray-200 mx-2 mb-1 px-3" @click="changeVal(-5)">-5</button>
            </div>
            <div class="text-4xl flex-grow align-middle" :class="couleur">{{ val }}</div>
            <div class="flex flex-col grow-0">
                <button class="border border-gray-600 rounded-md bg-gray-100 hover:bg-gray-200 mx-2 mb-1 px-3" @click="changeVal(1)">+1</button>
                <button class="border border-gray-600 rounded-md bg-gray-100 hover:bg-gray-200 mx-2 mb-1 px-3" @click="changeVal(5)">+5</button>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: String,
    val: Number,
    couleur: String
})

const emit = defineEmits(['changeVal'])

const changeVal = (val) => {
    emit("changeVal", val);
}
</script>
