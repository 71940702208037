import { ref } from 'vue'

export function useCounter(initialValue = 0){
    const count = ref(initialValue)
    const min = ref(0)
    const max = ref(Number.MAX_VALUE)
    const inc = (val) => {
        count.value += val
        if (count.value > max.value) count.value = max.value
        if (count.value < min.value) count.value = min.value
    }
    const dec = (val) => {
        count.value -= val
        if (count.value > max.value) count.value = max.value
        if (count.value < min.value) count.value = min.value
    }
    const set = (val) => {
        count.value = val
        if (count.value > max.value) count.value = max.value
        if (count.value < min.value) count.value = min.value
    }
    const setMax = (val) => {
        max.value = val
        if (count.value > max) count.value = max
    }
    const setMin = (val) => {
        min.value = val
        if (count.value < min) count.value = min
    }
    return { count, inc, dec, set, setMax, setMin }
}
